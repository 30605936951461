import React, { useCallback } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { sportmap } from "../utilities/sportmap";
import { useHistory } from "react-router-dom";
const gridStyle = {
  padding: "8px",
};

const ProfileForm = (props) => {
  const {
    active,
    meta,
    bio,
    handleSelection,
    listHistory,
    name,
    previousCompHistory,
    setBio,
    setName,
    setShowPasswordModal,
    setSocial,
    social,
    sport,
  } = props;
  const history = useHistory();
  const addCompHistory = useCallback(
    ({ idx, title, placings }) => {
      const compHistory = [...bio["Competition Highlights"]];
      if (title || title === "") compHistory[idx].title = title;
      if (placings || placings === "")
        compHistory[idx].placings = placings.split(",");
      setBio({ ...bio, "Competition Highlights": compHistory });
    },
    [bio, setBio]
  );

  const addCompRecord = useCallback(() => {
    const compHistory = bio["Competition Highlights"]
      ? [...bio["Competition Highlights"]]
      : [];
    compHistory.push({ title: "", placings: [] });
    setBio({ ...bio, "Competition Highlights": compHistory });
  }, [bio, setBio]);

  const removeCompRecord = useCallback(
    (idx) => {
      const compHistory = bio["Competition Highlights"]
        ? [...bio["Competition Highlights"]]
        : [];

      compHistory.splice(idx, 1);
      setBio({ ...bio, "Competition Highlights": compHistory });
    },
    [bio, setBio]
  );

  const confirmProfile = useCallback(() => {
    // confirm profile will open a modal where you enter the password, then hit submit
    setShowPasswordModal(true);
  }, [setShowPasswordModal]);

  return (
    <>
      <Grid item xs={8}>
        <Typography variant="h5" style={gridStyle}>
          Athlete Profile
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => history.push("/athlete-portal")}>Back</Button>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          required
          label="Name"
          value={name ?? ""}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Sport"
          value={sport ?? ""}
          name="sport"
          required
          onChange={handleSelection}
          fullWidth
        >
          {Object.keys(sportmap).map((key) => (
            <MenuItem key={key} value={sportmap[key]}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Competing in 2024"
          value={active ?? ""}
          required
          name="active"
          onChange={handleSelection}
          fullWidth
        >
          {["yes", "no"].map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          select
          label="Has Meta Image - Admin use only"
          value={meta ?? ""}
          required
          name="meta"
          onChange={handleSelection}
          fullWidth
        >
          {["yes", "no"].map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" style={gridStyle}>
          Bio
        </Typography>
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Birth Year"
          value={bio["Birth Year"] ?? ""}
          onChange={(e) => setBio({ ...bio, "Birth Year": e.target.value })}
          fullWidth
          type="number"
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Current City"
          value={bio["Current City"] ?? ""}
          onChange={(e) => setBio({ ...bio, "Current City": e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Division(s) you will compete in"
          value={bio["Division"] ?? ""}
          onChange={(e) =>
            setBio({
              ...bio,
              Division: e.target.value,
            })
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={3} style={gridStyle}>
        <TextField
          label="Vegan Since"
          value={bio["Vegan Since"] ?? ""}
          onChange={(e) => setBio({ ...bio, "Vegan Since": e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Why you became a vegan"
          value={bio["Why you became a vegan"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Why you became a vegan": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Favorite foods/meals"
          value={bio["Favorite foods/meals"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Favorite foods/meals": e.target.value })
          }
          fullWidth
          multiline
          minRows={1}
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <TextField
          label="Favorite exercises"
          value={bio["Favorite exercises"] ?? ""}
          onChange={(e) =>
            setBio({ ...bio, "Favorite exercises": e.target.value })
          }
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">List Competition Highlights</Typography>
      </Grid>
      {listHistory && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" style={gridStyle} align="center">
              Click "add another record" for each event you want to add details
              about
            </Typography>
          </Grid>
          {(bio["Competition Highlights"] ?? []).map((record, idx) => (
            <Grid
              key={idx}
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={5} style={gridStyle} key={`title:${idx}`}>
                <TextField
                  label="Event Title"
                  value={record.title ?? ""}
                  onChange={(e) =>
                    addCompHistory({ idx, title: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={5} style={gridStyle} key={`placings:${idx}`}>
                <TextField
                  label="Placings (if more than one sepperate by comma)"
                  value={record.placings?.join(",") ?? ""}
                  onChange={(e) =>
                    addCompHistory({ idx, placings: e.target.value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                key={`delete:${idx}`}
              >
                <Button onClick={() => removeCompRecord(idx)}>Delete</Button>
              </Grid>
            </Grid>
          ))}
          <Button fullWidth onClick={addCompRecord}>
            add another record
          </Button>
          {previousCompHistory && (
            <Grid item xs={12} style={{ paddingLeft: "16px" }}>
              <Typography variant="h6">
                Old Records for Reference **THESE WILL BE LOST ON NEXT SAVE**
              </Typography>
              <Typography variant="body1" color="secondary">
                {previousCompHistory}
              </Typography>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="h6" style={gridStyle}>
          Social Media Links (optional)
        </Typography>
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Website Link"
          value={social["Website"] ?? ""}
          onChange={(e) => setSocial({ ...social, Website: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.plantbuilt.com"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Instagram Link"
          value={social["Instagram"] ?? ""}
          onChange={(e) => setSocial({ ...social, Instagram: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.instagram.com/coolvegan"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Facebook Link"
          value={social["Facebook"] ?? ""}
          onChange={(e) => setSocial({ ...social, Facebook: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.facebook.com/myprofile"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="YouTube Link"
          value={social["YouTube"] ?? ""}
          onChange={(e) => setSocial({ ...social, YouTube: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.youtube.com/@CoolVeganAthlete"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Twitter Link"
          value={social["Twitter"] ?? ""}
          onChange={(e) => setSocial({ ...social, Twitter: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.twitter.com/myprofile"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="LinkedIn Link"
          value={social["LinkedIn"] ?? ""}
          onChange={(e) => setSocial({ ...social, LinkedIn: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.linkedin.com/in/coolvegan/"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="TikTok Link"
          value={social["TikTok"] ?? ""}
          onChange={(e) => setSocial({ ...social, TikTok: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://www.tiktok.com/@coolvegan"
        />
      </Grid>
      <Grid item xs={6} style={gridStyle}>
        <TextField
          label="Podcast Link"
          value={social["Podcast"] ?? ""}
          onChange={(e) => setSocial({ ...social, Podcast: e.target.value })}
          fullWidth
          helperText="Use full url: example - https://podcasts.apple.com/us/podcast/vegancast"
        />
      </Grid>
      <Grid item xs={12} style={gridStyle}>
        <Button fullWidth onClick={confirmProfile}>
          Save and Submit
        </Button>
      </Grid>
    </>
  );
};

export default ProfileForm;
