import React from "react";
import useWindowDimensions from "../../windowDimensions";

const linkText = {
  fontFamily: "Impact",
  fontSize: "2rem",
  margin: "8px",
  color: "black",
};

const linkTextSM = {
  fontFamily: "Impact",
  fontSize: "1.25rem",
  margin: "8px",
  color: "black",
};

const logoBlock = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const header = {
  fontFamily: "Impact",
  fontSize: "5rem",
  margin: "8px",
  color: "black",
};

const headerSM = {
  fontFamily: "Impact",
  fontSize: "2.5rem",
  margin: "8px",
  color: "black",
};

const body = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: "16px",
};

const pfStyle = { marginRight: "16px", width: "400px", height: "100px" };
const pfStyleSM = { marginRight: "16px", width: "150px", height: "30px" };

const unisoyStyle = { marginRight: "16px", width: "300px" };
const unisoyStyleSM = { marginRight: "16px", width: "135px" };

const Sponsors = () => {
  const { width } = useWindowDimensions();
  return (
    <div style={body}>
      <h2 style={width > 650 ? header : headerSM}>Our Sponsors:</h2>
      <div style={{ marginBottom: "8px" }}>
        <div style={logoBlock}>
          <img
            src="/PlantFusion_logo_color.png"
            alt="plant fusion"
            style={width > 650 ? pfStyle : pfStyleSM}
          />
          <img
            src="/ultima.png"
            alt="ultima"
            width={width > 650 ? "250px" : "100px"}
          />
        </div>
        <div style={logoBlock}>
          <img
            src="/unisoy_logo.png"
            alt="unisoy"
            style={width > 650 ? unisoyStyle : unisoyStyleSM}
          />
          <img
            src="/hodo_logo.png"
            alt="hodo"
            width={width > 650 ? "250px" : "115px"}
          />
        </div>
      </div>

      <a
        href="https://www.veganstrong.com/copy-of-support-us"
        target="_blank"
        rel="noreferrer"
        style={{
          color: "black",
        }}
      >
        <h4 style={width > 650 ? linkText : linkTextSM}>
          Learn how your business can help
        </h4>
      </a>
    </div>
  );
};

export default Sponsors;
