import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../windowDimensions";
import { joinText } from "./text";

const linkButton = {
  backgroundColor: "#769525",
  color: "black",
  width: "360px",
  height: "90px",
  borderRadius: "40px",
  border: "1px solid black",
  margin: "8px",
  fontSize: "2.75rem",
  fontFamily: "Impact",
  cursor: "pointer",
};

const linkButtonSM = {
  backgroundColor: "#769525",
  color: "black",
  width: "225px",
  height: "60px",
  borderRadius: "40px",
  border: "1px solid black",
  fontSize: "1.5rem",
  fontFamily: "Impact",
  margin: "16px",
  cursor: "pointer",
};

const textParagraph = {
  fontFamily: "Roboto",
  fontSize: "2.15rem",
  color: "#769525",
  margin: "2px",
};

const textParagraphSM = {
  fontFamily: "Roboto",
  fontSize: "1.2rem",
  textAlign: "center",
  color: "#769525",
  margin: "2px",
};

const header = {
  fontFamily: "Impact",
  fontSize: "5rem",
  margin: "2px",
  color: "white",
};

const headerSM = {
  fontFamily: "Impact",
  fontSize: "2.5rem",
  margin: "2px",
  color: "white",
};

const body = {
  backgroundColor: "black",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: "48px",
  paddingTop: "32px",
};

const bodySM = {
  backgroundColor: "black",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingBottom: "16px",
  paddingTop: "32px",
};

const Join = () => {
  const { width } = useWindowDimensions();
  return (
    <div style={width > 650 ? body : bodySM}>
      <h2 style={width > 650 ? header : headerSM}>Looking to Join Us?</h2>
      <div style={{ width: "80%", margin: "auto" }}>
        <p style={width > 650 ? textParagraph : textParagraphSM}>{joinText}</p>
      </div>
      <Link to="/contact">
        <button style={width > 650 ? linkButton : linkButtonSM}>APPLY</button>
      </Link>
    </div>
  );
};

export default Join;
