import React from "react";
import Hero from "./landingPage/Hero";
import { Helmet } from "react-helmet";
import UpcomingEvents from "./landingPage/UpcomingEvents";
import Sponsors from "./landingPage/Sponsors";
import Join from "./landingPage/Join";

const Main = () => {
  return (
    <>
      <Helmet>
        <meta
          property="og:image"
          content="https://plantbuilt.com/plantbuilt-team-photo-2023.jpeg"
        />
      </Helmet>
      <Hero />
      <UpcomingEvents />
      <Sponsors />
      <Join />
    </>
  );
};

export default Main;
