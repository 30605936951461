import React from "react";
import useWindowDimensions from "../../windowDimensions";

const linkButton = {
  backgroundColor: "black",
  color: "white",
  width: "360px",
  height: "90px",
  borderRadius: "40px",
  border: "1px solid black",
  fontSize: "2.75rem",
  fontFamily: "Impact",
  margin: "16px",
  cursor: "pointer",
};
const linkButtonSM = {
  backgroundColor: "black",
  color: "white",
  width: "225px",
  height: "60px",
  borderRadius: "40px",
  border: "1px solid black",
  fontSize: "1.5rem",
  fontFamily: "Impact",
  margin: "16px",
  cursor: "pointer",
};

const subtitleText = {
  fontFamily: "Roboto",
  fontSize: "2rem",
  color: "black",
  margin: "8px",
};

const subtitleTextSM = {
  fontFamily: "Roboto",
  fontSize: "1.25rem",
  color: "black",
  margin: "8px",
  textAlign: "center",
};

const header = {
  fontFamily: "Impact",
  fontSize: "2.75rem",
  color: "black",
  margin: "8px",
};
const headerSM = {
  fontFamily: "Impact",
  fontSize: "1.75rem",
  color: "black",
  margin: "8px",
  textAlign: "center",
};

const body = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginTop: "8px",
  marginBottom: "48px",
};
const bodySM = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginTop: "8px",
  marginBottom: "16px",
};

const imageStyle = { width: "425px", margin: "8px" };
const imageStyleSM = { width: "300px", margin: "8px" };

const EventCard = ({ title, subtitle, image, link }) => {
  const { width } = useWindowDimensions();
  return (
    <article style={width > 650 ? body : bodySM}>
      <h4 style={width > 650 ? header : headerSM}>{title}</h4>
      <p style={width > 650 ? subtitleText : subtitleTextSM}>{subtitle}</p>
      <img
        src={image}
        alt={title}
        style={width > 650 ? imageStyle : imageStyleSM}
      />
      <a href={link} target="_blank" rel="noreferrer">
        <button style={width > 650 ? linkButton : linkButtonSM}>
          LEARN MORE
        </button>
      </a>
    </article>
  );
};

export default EventCard;
