import React from "react";
import eventData from "../../data/eventData.json";
import useWindowDimensions from "../../windowDimensions";
import EventCard from "./EventCard";

const body = {
  backgroundColor: "#769525",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "flex-start",
};
const header = { fontFamily: "Impact", fontSize: "5rem", margin: "8px" };
const headerSM = { fontFamily: "Impact", fontSize: "2.5rem", margin: "8px" };

const UpcomingEvents = () => {
  const { width } = useWindowDimensions();
  return (
    <div style={body}>
      <h2 style={width > 650 ? header : headerSM}>Upcoming Events</h2>
      {eventData.map((e) => (
        <EventCard
          title={e.title}
          subtitle={e.subtitle}
          image={e.image}
          link={e.link}
        />
      ))}
    </div>
  );
};

export default UpcomingEvents;
