import React from "react";
import { heroText } from "./text";
import useWindowDimensions from "../../windowDimensions";

const paragraphText = { fontFamily: "Roboto", fontSize: "2.15rem" };
const paragraphTextSM = {
  fontFamily: "Roboto",
  fontSize: "1.2rem",
  textAlign: "center",
};

const Hero = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      {width > 650 ? (
        <>
          <video
            width="100%"
            height="600px"
            loop="true"
            autoplay="autoplay"
            controls
            muted
            style={{ objectFit: "cover", padding: "0" }}
          >
            <source
              src="https://plantbuilt.s3.us-west-2.amazonaws.com/plantbuiltHeroVideo.webm"
              type="video/webm"
            />
          </video>
          <div style={{ width: "80%", margin: "auto" }}>
            <p style={paragraphText}>{heroText}</p>
          </div>
        </>
      ) : (
        <>
          <video
            width="100%"
            height="200px"
            loop="true"
            autoplay="autoplay"
            controls
            muted
            style={{ objectFit: "cover", padding: "0" }}
          >
            <source
              src="https://plantbuilt.s3.us-west-2.amazonaws.com/plantbuiltHeroVideo.webm"
              type="video/webm"
            />
          </video>
          <div style={{ width: "80%", margin: "auto" }}>
            <p style={paragraphTextSM}>{heroText}</p>
          </div>
        </>
      )}
    </>
  );
};

export default Hero;
