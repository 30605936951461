import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import logo from "../PlantBuilt-2023-logo.png";
import useWindowDimensions from "../windowDimensions";
import NavMenu from "./NavMenu";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles({
  logo: {
    marginTop: "8px",
    width: "550px",
  },
  logoSM: {
    marginTop: "8px",
    width: "250px",
  },
  text: { color: "#769525", fontFamily: "Impact", fontSize: "1.7rem" },
  link: { textDecoration: "none" },
});

const appBarStyle = {
  backgroundColor: "black",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const toolbarStyle = {
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
};

const buttonLink = {
  color: "#769525",
  fontFamily: "Impact",
  fontSize: "1.75rem",
  textDecoration: "none",
};

const menuItems = [
  "All Sports",
  "Bodybuilding",
  "CF",
  "Kettlebell Sport",
  "Powerlifting",
  "Strongman",
  // "Olympic Weightlifting",
  "Legacy Athletes",
];

const resultsMenu = [
  "2013 Results",
  "2014 Results",
  "2015 Results",
  "2017 Results",
  "2022 Results",
  "2023 Results",
  "2024 Results",
];

const aboutMenu = ["About", "Contact"];

export default function ButtonAppBar() {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 650 ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={appBarStyle}>
            <Link style={buttonLink} to="/">
              <img src={logo} alt="plantbuilt" className={classes.logo} />
            </Link>
            <Toolbar sx={toolbarStyle}>
              <NavMenu
                className={classes.links}
                title={"Athletes"}
                menuItems={menuItems}
              />
              <NavMenu
                className={classes.links}
                title={"Results"}
                menuItems={resultsMenu}
              />
              <Link style={buttonLink} to="/sponsors">
                SUPPORT
              </Link>
              <a
                href="https://www.veganstrong.com/copy-of-media"
                target="_blank"
                rel="noreferrer"
              >
                <Button id="Nav-button" sx={buttonLink}>
                  MEDIA
                </Button>
              </a>

              <NavMenu
                className={classes.links}
                title={"About Us"}
                menuItems={aboutMenu}
              />
              <a
                href="https://www.veganstrong.com/shop-1/"
                target="_blank"
                rel="noreferrer"
              >
                <Button id="Nav-button" sx={buttonLink}>
                  SHOP
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Box>
      ) : (
        <AppBar position="static" style={appBarStyle}>
          <Toolbar sx={toolbarStyle}>
            <NavMenu
              item={"open"}
              menuItems={["Athletes", "2024 Results", "Contact", "About"]}
              icon={<MenuIcon />}
            />
            <Link to="/" style={{ width: "250px" }}>
              <img src={logo} alt="plantbuilt" className={classes.logoSM} />
            </Link>
            <NavMenu item={""} menuItems={[]} icon={""} />
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
