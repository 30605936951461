import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Avatar,
  IconButton,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CloseIcon from "@mui/icons-material/Close";
import SocialMediaIcons from "./SocialMediaIcons";
import useWindowDimensions from "../windowDimensions";

const ProfileModal = ({ setShowModal, sport, selectAthlete }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();

  useEffect(() => {
    document.title = `Vegan Strong PlantBuilt Team: ${selectAthlete.name}`;
  }, [selectAthlete]);

  const handleCloseModal = () => {
    const lastPageArray = history.location.pathname.split("/");
    lastPageArray.pop();
    const lastPage = lastPageArray.join("/");
    history.push(lastPage);
    setShowModal(false);
  };

  const renderAthleteSocial = () => {
    if (selectAthlete.social) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: width > 900 ? "flex-start" : "center",
          }}
        >
          {selectAthlete.social.map((element, elemIdx) => (
            <SocialMediaIcons key={elemIdx} socialLink={element} />
          ))}
        </div>
      );
    }
    return "";
  };

  const renderAthleteBio = () => {
    if (selectAthlete.bio) {
      const bio = selectAthlete.bio.map((question) => {
        if (question.key === "Birth Year") {
          return (
            <Typography
              key={question.key}
              style={{
                fontFamily: "Impact",
                fontSize: "1.5rem",
                lineHeight: "3rem",
              }}
              paragraph
              variant="body1"
            >
              <b style={{ color: "#769525" }}>Age: </b>
              {question.value && parseInt(question.value)
                ? new Date().getFullYear() - parseInt(question.value)
                : "Vegans are Ageless"}
            </Typography>
          );
        } else if (question.key === "Vegan Since") {
          return (
            <Typography
              key={question.key}
              style={{
                fontFamily: "Impact",
                fontSize: "1.5rem",
                lineHeight: "3rem",
              }}
              paragraph
              variant="body1"
            >
              <b style={{ color: "#769525" }}>Years as a Vegan: </b>
              {question.value && parseInt(question.value)
                ? new Date().getFullYear() - parseInt(question.value)
                : "Unsure"}
            </Typography>
          );
        } else if (question.key === "Competition Highlights") {
          return (
            <div key={question.key}>
              <Typography
                variant="body1"
                style={{
                  color: "#769525",
                  fontFamily: "Impact",
                  fontSize: "1.5rem",
                }}
              >
                <b>{question.key}</b>:
              </Typography>
              {question.value.reverse().map((event, eventIdx) => (
                <div style={{ marginBottom: "32px" }}>
                  <p
                    key={eventIdx}
                    style={{
                      fontFamily: "Impact",
                      fontSize: "1.5rem",
                      marginTop: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    {event.title}
                  </p>
                  <ul style={{ margin: "8px 0px" }}>
                    {(event.placings || []).map((placing, eventIdx) => (
                      <li
                        style={{
                          fontFamily: "Impact",
                          fontSize: "1.25rem",
                        }}
                        key={eventIdx}
                      >
                        {placing}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          );
        } else {
          return (
            <Typography
              key={question.key}
              style={{
                fontFamily: "Impact",
                fontSize: "1.5rem",
                lineHeight: "3rem",
              }}
              paragraph
              variant="body1"
            >
              <b style={{ color: "#769525" }}>{question.key}: </b>
              {question.value}
            </Typography>
          );
        }
      });
      return bio;
    }
    return "Bio coming soon!";
  };

  return (
    <Card>
      <Helmet>
        <meta
          property="og:title"
          content={`Vegan Strong PlantBuilt Team: ${selectAthlete.name}`}
        />
        <meta
          property="og:image"
          content={`https://plantbuilt.com${
            selectAthlete.meta || "/plantbuilt-default-meta.png"
          }`}
        />
      </Helmet>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "#709425" }} aria-label="recipe">
            <FitnessCenterIcon />
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        }
        title={selectAthlete.name}
        subheader={`${sport}${
          selectAthlete.name === "Giacomo Marchese" ||
          selectAthlete.name === "Dani Taylor"
            ? " - PlantBuilt Co-Founder"
            : ""
        }`}
      />
      <div
        style={{
          display: "flex",
          flexDirection: width > 900 ? "row" : "column",
        }}
      >
        <div>
          <CardMedia
            component="img"
            sx={{
              width: width > 900 ? "400px" : "450px",
              margin: width > 900 ? "0px 0px 0px 15px" : "auto",
            }}
            image={selectAthlete.img}
            alt={selectAthlete.name}
          />
          <CardContent>{renderAthleteSocial()}</CardContent>
        </div>
        <div>
          <CardContent sx={{ paddingTop: "0px" }}>
            {renderAthleteBio()}
          </CardContent>
        </div>
      </div>
    </Card>
  );
};

export default ProfileModal;
