import React, { useCallback, useEffect, useState } from "react";

import { Alert, Grid, Snackbar } from "@mui/material";
import { sportmap, sportmapReverse } from "../utilities/sportmap";
import { useHistory, useLocation } from "react-router-dom";
import profileTemplate from "../data/profileTemplate.json";
import { uploadFile } from "../utilities/updateS3";
import ProfileForm from "./ProfileForm";
import PasswordModal from "./PasswordModal";

const UpdateProfile = () => {
  const location = useLocation();
  const { userProfile } = location.state;

  const [name, setName] = useState(userProfile.name ?? "");
  const [sport, setSport] = useState(
    userProfile.sport ? sportmap[userProfile.sport] : ""
  );
  const [active, setActive] = useState(userProfile.active ? "yes" : "no");
  const [meta, setMeta] = useState(userProfile.meta ? "yes" : "no");
  const [bio, setBio] = useState({});
  const [social, setSocial] = useState({});
  const [listHistory, setListHistory] = useState(true);
  const [updatedState, setUpdatedState] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alertText, setAlertText] = useState("");
  const [previousCompHistory, setPreviousCompHistory] = useState(null);

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!userProfile) history.push("/athlete-portal");
    if (userProfile && !updatedState) {
      setUpdatedState(true);
      let updatedSocials = {};
      // takes user bio on file and turns it into a map
      const bioMap = {};
      userProfile.bio.forEach((question) => {
        bioMap[question.key.toLowerCase()] = question.value;
        if (question.key === "Competition history")
          setPreviousCompHistory(question.value.join(", "));
        if (question.key === "History competing")
          setPreviousCompHistory(question.value);
      });
      const updatedBio = {};
      // reconciles user profile on file with current template
      profileTemplate.forEach((question) => {
        if (bioMap[question.key.toLowerCase()])
          updatedBio[question.key] = bioMap[question.key.toLowerCase()];
        else if (question.key === "Current City")
          updatedBio[question.key] = bioMap["hometown/city"];
        else if (question.key === "Birth Year")
          updatedBio[question.key] = bioMap["age"];
        else if (question.key === "Vegan Since")
          updatedBio[question.key] = bioMap["years as a vegan"];
        else if (question.key === "Division")
          updatedBio[question.key] = bioMap["division(s) you will compete in"];
        else updatedBio[question.key] = question.value;
      });

      userProfile.social.forEach(
        (link) => (updatedSocials[link.key] = link.value)
      );

      setBio(updatedBio);
      setSocial(updatedSocials);
    }
  }, [updatedState, userProfile, history]);

  const handleSelection = useCallback(
    (e) => {
      const eventName = e.target.name;
      const eventValue = e.target.value;
      if (eventName === "sport") {
        setSport(eventValue);
      }
      if (eventName === "active") {
        setActive(eventValue);
      }
      if (eventName === "listHistory") {
        setListHistory(!listHistory);
      }
      if (eventName === "meta") {
        setMeta(eventValue);
      }
    },
    [listHistory]
  );

  const buildBio = useCallback(() => {
    const orderedBio = [];
    if (bio["Current City"])
      orderedBio.push({ key: "Current City", value: bio["Current City"] });
    if (bio["Birth Year"])
      orderedBio.push({ key: "Birth Year", value: bio["Birth Year"] });
    if (bio["Division"])
      orderedBio.push({
        key: "Division",
        value: bio["Division"],
      });
    if (bio["Vegan Since"])
      orderedBio.push({
        key: "Vegan Since",
        value: bio["Vegan Since"],
      });
    if (bio["Why you became a vegan"])
      orderedBio.push({
        key: "Why you became a vegan",
        value: bio["Why you became a vegan"],
      });
    if (bio["Favorite foods/meals"])
      orderedBio.push({
        key: "Favorite foods/meals",
        value: bio["Favorite foods/meals"],
      });

    if (bio["Favorite exercises"])
      orderedBio.push({
        key: "Favorite exercises",
        value: bio["Favorite exercises"],
      });

    if (listHistory && bio["Competition Highlights"]?.length) {
      orderedBio.push({
        key: "Competition Highlights",
        value: bio["Competition Highlights"],
      });
    }
    return orderedBio;
  }, [bio, listHistory]);

  const submitProfile = useCallback(async () => {
    if (!name || !active || !sport) {
      setAlertStatus("error");
      setShowAlert(true);
      setAlertText(
        "You must fill in the required top 3 fields before submitting the form"
      );
      return;
    }
    const profile = {
      name: name,
      id: Number(userProfile.id),
      img: userProfile.img ?? "",
      meta: userProfile.meta
        ? userProfile.meta
        : meta
        ? `/profile-photos/plantbuilt-${name
            .toLowerCase()
            .replace(" ", "-")}-meta.png`
        : null,
      active: active === "yes" ? true : false,
      sport: sportmapReverse[sport],
      bio: [],
      social: [],
    };

    profile.bio = buildBio();

    for (let link in social) {
      if (social[link]) profile.social.push({ key: link, value: social[link] });
    }

    const stringProfiles = JSON.stringify(profile);
    const response = await uploadFile(password, userProfile.id, stringProfiles);
    if (response.status === 200) {
      setAlertStatus("success");
      setShowAlert(true);
      setAlertText("Your profile has been updated");
    } else {
      setAlertStatus("error");
      setShowAlert(true);
      setAlertText(
        "There was an issue updating your profile, please contact admin"
      );
    }
  }, [active, buildBio, meta, name, password, social, sport, userProfile]);

  return (
    <Grid container>
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity={alertStatus}
            variant="filled"
          >
            {alertText}
          </Alert>
        </Snackbar>
      )}
      <ProfileForm
        active={active}
        bio={bio}
        handleSelection={handleSelection}
        listHistory={listHistory}
        name={name}
        setBio={setBio}
        setName={setName}
        setSocial={setSocial}
        setShowPasswordModal={setShowPasswordModal}
        social={social}
        sport={sport}
        meta={meta}
        previousCompHistory={previousCompHistory}
      />
      <PasswordModal
        showPasswordModal={showPasswordModal}
        hideModal={() => setShowPasswordModal(false)}
        password={password}
        setPassword={setPassword}
        submitProfile={submitProfile}
      />
    </Grid>
  );
};

export default UpdateProfile;
